<template>
  <div class="ma-2">
    <data-iterator :headers="leaveTypesHeaders" :payload="leaveTypesPayload"></data-iterator>
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card elevation="0" tile>
        <v-card-title class="primary darken-2 white--text pa-4 pb-3">
          <span class="body-1 font-weight-medium">
            {{ leaveTypeObj._id ? 'Update Leave type' : 'Add Leave type' }}
          </span>
        </v-card-title>
        <v-form ref="form">
          <v-card-text class="pa-0">
            <v-card flat max-height="61vh" class="overflow-y-auto pa-2 pb-0">
              <v-container fluid class="pa-1">
                <v-row no-gutters>
                  <v-col cols="12" lg="6" class="pa-1">
                    <v-text-field outlined dense hide-details label="Name" v-model="leaveTypeObj.name" :rules="setValidation('requiredValidation')"
                    validate-on-blur></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="pa-1">
                    <v-text-field outlined dense hide-details label="Code" v-model="leaveTypeObj.code" :rules="setValidation('requiredValidation')"
                    validate-on-blur></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="pa-1">
                    <v-menu ref="menu" v-model="leaveTypeObj.menu" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto">
                      <template #activator="{ on, attrs }">
                        <v-text-field hide-details outlined dense v-model="leaveTypeObj.color" label="Color" append-icon="mdi-format-color-fill" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-color-picker mode="hexa" v-model="leaveTypeObj.colorObj" scrollable @change="leaveTypeObj.color = leaveTypeObj.colorObj.hexa || ''"></v-color-picker>
                      <v-card tile flat>
                        <div>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="leaveTypeObj.menu = false" > Cancel </v-btn>
                          <v-btn text color="primary" @click="leaveTypeObj.color = leaveTypeObj.colorObj.hexa || ''; leaveTypeObj.menu = false" > OK </v-btn>
                        </div>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="6" class="pa-1">
                    <v-select outlined dense hide-details :items="leaveTypes" item-text="text" item-value="value" label="Type" v-model="leaveTypeObj.type"></v-select>
                  </v-col>
                  <v-col cols="12" lg="6" class="pa-1">
                    <v-textarea rows="1" outlined dense hide-details label="Description" v-model="leaveTypeObj.description"></v-textarea>
                  </v-col>
                  <v-col cols="12" lg="6" class="pa-1" align-self="center">
                    <v-checkbox outlined dense hide-details label="Is Active" v-model="leaveTypeObj.isactive"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="pa-1">
                    <v-tabs v-model="leaveTab">
                      <v-tab class="text-capitalize"> Entitlement </v-tab>
                      <v-tab class="text-capitalize"> Applicable </v-tab>
                      <v-tab class="text-capitalize"> Restrictions </v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                    <v-tabs-items v-model="leaveTab">
                      <!-- entitlement -->
                      <v-tab-item>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" class="d-inline-flex align-center">
                              <v-checkbox hide-details dense v-model="leaveTypeObj.haslimit" class="ma-0"
                              @change="leaveTypeObj.haslimit ? '' : leaveTypeObj.hasresetlimit = leaveTypeObj.havecarryforward = false;"></v-checkbox>
                              <span> Set limit to </span>
                              <v-text-field style="max-width: 100px" type="number" outlined hide-details dense class="mx-2" v-model.number="leaveTypeObj.totallimit"
                              :rules="leaveTypeObj.haslimit ? setValidation('requiredValidation') : []" @keypress="positiveNumberValidation"
                              @change="leaveTypeObj.totallimit = leaveTypeObj.totallimit || (leaveTypeObj.haslimit ? 1 : null)"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="d-inline-flex align-center pt-0">
                              <v-card flat :disabled="!leaveTypeObj.haslimit" class="d-inline-flex align-center">
                                <v-checkbox hide-details dense v-model="leaveTypeObj.hasresetlimit" class="ma-0"
                                @change="leaveTypeObj.hasresetlimit ? '' : leaveTypeObj.havecarryforward = false;"></v-checkbox>
                                <span> Reset limit every </span>
                                <span class="px-1 font-weight-bold grey--text text--darken-3"> YEAR </span>
                                <!-- <v-select style="max-width: 100px;" outlined hide-details dense class="mx-2" :items="['month', 'year']" single-line v-model="leaveTypeObj.resetat"></v-select> -->
                                <span> on </span>
                                <v-select :items="monthsList" outlined hide-details dense style="max-width: 120px;" class="mx-2" @change="getDaysInMonth(leaveTypeObj.resetmonth)" v-if="leaveTypeObj.resetat === 'year'" v-model="leaveTypeObj.resetmonth"></v-select>
                                <v-select :items="daysList" outlined hide-details dense style="max-width: 120px;" class="mx-1" v-if="leaveTypeObj.resetmonth" v-model="leaveTypeObj.resetday"></v-select>
                              </v-card>
                            </v-col>
                            <v-col cols="12" class="align-center d-inline-flex pt-0">
                              <!-- <div class="mx-3"></div> -->
                              <v-checkbox hide-details dense v-model="leaveTypeObj.havecarryforward" class="ma-0" :disabled="!leaveTypeObj.hasresetlimit"></v-checkbox>
                              <span :class="['mr-2 my-auto py-auto', (leaveTypeObj.hasresetlimit ? '' : 'grey--text')]"> Carry forward remainig balance </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                      <!-- applicable -->
                      <v-tab-item>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" lg="6" class="py-2">
                              <p class="font-weight-bold primary--text text--darken-3 body-2 mb-2"> Applicable for </p>
                              <v-divider class="my-1 mr-4"></v-divider>
                              <v-layout row wrap align-center class="ma-0">
                                <v-flex class="d-flex py-1">
                                  <span class="mr-2 my-auto body-2 grey--text text--darken-3"> Gender </span>
                                  <v-checkbox value="male" hide-details dense v-model="leaveTypeObj.applicable_gender" multiple
                                  class="ma-0 mx-1" label="Male"></v-checkbox>
                                  <v-checkbox value="female" hide-details dense v-model="leaveTypeObj.applicable_gender" multiple
                                  class="ma-0 mx-1" label="Female"></v-checkbox>
                                  <v-checkbox value="other" hide-details dense v-model="leaveTypeObj.applicable_gender" multiple
                                  class="ma-0 mx-1" label="Others"></v-checkbox>
                                </v-flex>
                                <v-flex class="py-1">
                                  <v-select multiple clearable label="Roles" dense outlined item-text="name" item-value="_id" :items="listOfUserRoles"
                                  hide-details v-model="leaveTypeObj.applicable_roles">
                                    <template #selection="{ item, index }">
                                      <v-chip v-if="index === 0" small>
                                        <span> {{ item.name }} </span>
                                      </v-chip>
                                      <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ leaveTypeObj.applicable_roles.length - 1 }} others)
                                      </span>
                                    </template>
                                  </v-select>
                                </v-flex>
                                <v-flex class="py-1">
                                  <v-autocomplete multiple clearable label="Employees" outlined dense hide-details
                                  :items="listOfEmployees" :item-text="(val) => `${val.formdata.name} ${(val.role && val.role.name) ? '(' + val.role.name + ')' : ''}`"
                                  item-value="_id" v-model="leaveTypeObj.applicable_users">
                                    <!-- <template #selection="{ item, index }" v-if="item && item.formdata && item.formdata.name">
                                      <v-chip v-if="index === 0" small>
                                        <span> {{ item.formdata.name }} </span>
                                      </v-chip>
                                      <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ leaveTypeObj.applicable_roles.length - 1 }} others)
                                      </span>
                                    </template> -->
                                  </v-autocomplete>
                                </v-flex>
                                <v-flex class="py-1">
                                  <v-select multiple clearable label="Shifts" dense outlined item-text="name" item-value="_id" :items="listOfShifts"
                                  hide-details v-model="leaveTypeObj.applicable_shifts">
                                    <template #selection="{ item, index }">
                                      <v-chip v-if="index === 0" small>
                                        <span> {{ item.name }} </span>
                                      </v-chip>
                                      <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ leaveTypeObj.applicable_shifts.length - 1 }} others)
                                      </span>
                                    </template>
                                  </v-select>
                                </v-flex>
                              </v-layout>
                            </v-col>
                            <v-divider vertical class="my-4"></v-divider>
                            <v-col cols="12" lg="6" class="py-2">
                              <p class="font-weight-bold primary--text text--darken-3 body-2 mb-2"> Except for </p>
                              <v-divider class="my-1 mr-4"></v-divider>
                              <v-layout row wrap align-center class="ma-0">
                                <v-flex class="py-1">
                                  <v-select multiple clearable label="Role" dense outlined item-text="name" item-value="_id" :items="listOfUserRoles"
                                  hide-details v-model="leaveTypeObj.exception_roles">
                                    <template #selection="{ item, index }">
                                      <v-chip v-if="index === 0" small>
                                        <span> {{ item.name }} </span>
                                      </v-chip>
                                      <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ leaveTypeObj.exception_roles.length - 1 }} others)
                                      </span>
                                    </template>
                                  </v-select>
                                </v-flex>
                                <v-flex class="py-1">
                                  <v-autocomplete multiple clearable label="Employee" outlined dense :items="listOfEmployees"
                                  :item-text="(val) => `${val.formdata.name} ${(val.role && val.role.name) ? '(' + val.role.name + ')' : ''}`"
                                  item-value="_id" v-model="leaveTypeObj.exception_users" hide-details>
                                    <!-- <template #selection="{ item, index }">
                                      <v-chip v-if="index === 0" small>
                                        <span> {{ item.name }} </span>
                                      </v-chip>
                                      <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ leaveTypeObj.applicable_roles.length - 1 }} others)
                                      </span>
                                    </template> -->
                                  </v-autocomplete>
                                </v-flex>
                                <v-flex class="py-1">
                                  <v-select multiple clearable label="Shifts" dense outlined item-text="name" item-value="_id" :items="listOfShifts"
                                  hide-details v-model="leaveTypeObj.exception_shifts">
                                    <template #selection="{ item, index }">
                                      <v-chip v-if="index === 0" small>
                                        <span> {{ item.name }} </span>
                                      </v-chip>
                                      <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ leaveTypeObj.exception_shifts.length - 1 }} others)
                                      </span>
                                    </template>
                                  </v-select>
                                </v-flex>
                              </v-layout>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" class="grey lighten-3 text-center">
                              <span class="caption grey--text text--darken-3 font-weight-medium"> ( <span class="font-weight-black info--text text--darken-2">Note</span>: If no other options selected, leave type will be applied for all ) </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                      <!-- restrictions -->
                      <v-tab-item>
                        <v-container fluid class="px-0">
                          <v-row class="ml-1">
                            <v-col cols="12" class="pa-2">
                              <div class="d-flex">
                                <v-checkbox hide-details dense v-model="leaveTypeObj.allow_excess_limit" class="ma-0"></v-checkbox>
                                <span class="mr-2 my-auto py-auto body-2 font-weight-medium grey--text text--darken-3"> Allow Applying Leave, when Exceed Leave Balance </span>
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-2 pb-0">
                              <span class="font-weight-bold primary--text text--darken-3 body-2"> Allow requests for: </span>
                            </v-col>
                          </v-row>
                          <v-row class="ml-1">
                            <v-col cols="3" class="d-flex pl-2">
                              <v-checkbox hide-details dense v-model="leaveTypeObj.can_request_past"></v-checkbox>
                              <span class="my-auto mt-2">Past</span>
                              <span>
                                <v-text-field style="width: 40px;" hide-details dense class="mx-2" v-model="leaveTypeObj.past_day_request_limit"
                                :rules="leaveTypeObj.can_request_past ? setValidation('requiredValidation') : []" @keypress="positiveNumberValidation"
                                @change="leaveTypeObj.past_day_request_limit = leaveTypeObj.past_day_request_limit || (leaveTypeObj.can_request_past ? 1 : null)"></v-text-field>
                              </span>
                              <span class="my-auto mt-2">Days.</span>
                            </v-col>
                            <v-col cols="3" class="d-flex px-2">
                              <v-checkbox hide-details dense v-model="leaveTypeObj.can_request_future"></v-checkbox>
                              <span class="my-auto mt-2">Future</span>
                              <span> 
                                <v-text-field style="width: 40px;" hide-details dense class="mx-2" v-model="leaveTypeObj.future_day_request_limit"
                                :rules="leaveTypeObj.can_request_future ? setValidation('requiredValidation') : []" @keypress="positiveNumberValidation"
                                @change="leaveTypeObj.future_day_request_limit = leaveTypeObj.future_day_request_limit || (leaveTypeObj.can_request_future ? 1 : null)"></v-text-field>
                              </span>
                              <span class="my-auto mt-2">Days.</span>
                            </v-col>
                            <v-col cols="6" class="d-flex pl-5">
                              <v-checkbox hide-details dense v-model="leaveTypeObj.have_days_before_request_restriction"></v-checkbox>
                              <span class="my-auto mt-2">To be applied </span>
                              <span> 
                                <v-text-field style="width: 40px;" hide-details dense class="mx-2" v-model.number="leaveTypeObj.days_before_request"
                                :rules="leaveTypeObj.have_days_before_request_restriction ? setValidation('requiredValidation') : []" @keypress="positiveNumberValidation"
                                @change="leaveTypeObj.days_before_request = leaveTypeObj.days_before_request || (leaveTypeObj.have_days_before_request_restriction ? 1 : null)"></v-text-field>
                              </span>
                              <span class="my-auto mt-2">days in advance.</span>
                            </v-col>
                          </v-row>
                          <v-row class="ml-1">
                            <v-col cols="12" class="pa-2">
                              <div class="d-flex align-center">
                                <v-checkbox hide-details dense v-model="leaveTypeObj.have_maximum_limit" class="ma-0"></v-checkbox>
                                <span class="mr-2"> Restrict maximum days can apply for leave </span>
                                <v-text-field style="max-width: 80px;" hide-details dense class="mx-2"
                                v-model.number="leaveTypeObj.maximum_days_limit" suffix="days"
                                :rules="leaveTypeObj.have_minimum_gap ? setValidation('requiredValidation') : []" @keypress="positiveNumberValidation"
                                @change="leaveTypeObj.maximum_days_limit = leaveTypeObj.maximum_days_limit || (leaveTypeObj.have_maximum_limit ? 1 : null)"></v-text-field>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="ml-1">
                            <v-col cols="12" class="pa-2">
                              <div class="d-flex align-center">
                                <v-checkbox hide-details dense v-model="leaveTypeObj.have_minimum_gap" class="ma-0"></v-checkbox>
                                <span class="mr-2"> Minimum gap between two applications </span>
                                <v-text-field style="max-width: 80px;" hide-details dense class="mx-2"
                                v-model.number="leaveTypeObj.minimum_gap_days" suffix="days"
                                :rules="leaveTypeObj.have_minimum_gap ? setValidation('requiredValidation') : []" @keypress="positiveNumberValidation"
                                @change="leaveTypeObj.minimum_gap_days = leaveTypeObj.minimum_gap_days || (leaveTypeObj.have_minimum_gap ? 1 : null)"></v-text-field>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="ml-1">
                            <v-col cols="12" class="pa-2">
                              <div class="d-flex">
                                <v-checkbox hide-details dense v-model="leaveTypeObj.have_maximum_limit_in_period" class="ma-0"></v-checkbox>
                                <span class="mr-2"> Maximum number of applications allowed within the specified period </span>
                              </div>
                              <div class="d-flex px-6 pt-2 pb-0">
                                <v-text-field style="max-width: 100px;" outlined hide-details dense class="mx-2" v-model="leaveTypeObj.maximum_limit_in_period"
                                :rules="leaveTypeObj.have_maximum_limit_in_period ? setValidation('requiredValidation') : []" @keypress="positiveNumberValidation"
                                @change="leaveTypeObj.maximum_limit_in_period = leaveTypeObj.maximum_limit_in_period || (leaveTypeObj.have_maximum_limit_in_period ? 1 : null)"></v-text-field>
                                <v-select style="max-width: 150px;" outlined hide-details dense class="mx-2" :items="['week', 'month', 'year']"
                                single-line v-model="leaveTypeObj.maximum_limit_in_period_for"></v-select>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="leaveTypeObj._id" color="success" @click="checkCanUpdateBalanceDialog()"> Update </v-btn>
          <v-btn v-else color="success" @click="saveUpdateHandler({ type: 'save' })"> Save </v-btn>
          <v-btn @click="dialog = false; $refs.form.reset(); leaveTypeObj = {};" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="leaveTypeUpdateConfirmDialog" width="750" persistent>
      <v-card>
        <v-card-title class="pa-4 pt-5 error darken-2 white--text">
          <v-icon dark> mdi-alert-box </v-icon>
          <span class="body-1 mx-2 font-weight-medium"> Confirmation! </span>
        </v-card-title>
        <v-card-text class="py-3 px-4">
          <!-- reset -->
          <template>
            <v-alert type="error" outlined border="left" class="ma-0 body-2">
              <div class="my-1">
                <span class="font-weight-bold"> Reset: </span> This will reset all user's leavetype balance
                and <span class="font-weight-bold"> will not adjust </span> the leavebalance of already taken leaves.
              </div>
              <div class="my-1" v-if="!leaveTypeObj.noReCalculateUpdate">
                <span class="font-weight-bold"> Re-calculate: </span> This will reset all user's leavetype balance
                  and <span class="font-weight-bold"> will adjust the remaining balance </span> according to leavetype balance of all users.
              </div>
              <div class="my-1">
                <span class="font-weight-bold"> Update: </span> This will not adjust existing user's leavetype balance
                  and <span class="font-weight-bold"> it updates only the leavetype. </span>
              </div>
            </v-alert>
          </template>
          <!-- recalculate -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="saveUpdateHandler({ type: 'reset' })"> Reset </v-btn>
            <v-btn v-if="!leaveTypeObj.noReCalculateUpdate" color="success" @click="saveUpdateHandler({ type: 'recalculate' })"> Re-calculate </v-btn>
            <v-btn color="success" @click="saveUpdateHandler({ type: 'update' })"> Update </v-btn>
            <v-btn color="error" outlined @click="leaveTypeUpdateConfirmDialog = false;"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      leaveTab: 0,
      leaveTypeObj: {},
      dialog: false,
      leaveTypesPayload: {
        hideTop: true,
        // title: 'Attendance Types',
        showBackBtn: true,
        create: this.openDialog,
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          show: (item) => !item.isinternal,
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      },
      listOfUserRoles: [],
      listOfShifts: [],
      appLogo: null,
      appObj: {
        smtp: {}
      },
      valid: false,
      logo: null,
      reRender: 0,
      listOfEmployees: [],
      requestPersons: [],
      daysList: [],
      cloneLeaveTypeObj: {},
      leaveTypeUpdateConfirmDialog: false
    }
  },
  computed: {
    leaveTypes () {
      return [{
        text: 'Paid',
        value: 'paid'
      },
      {
        text: 'Unpaid',
        value: 'unpaid'
      }]
    },
    leaveTypesHeaders () {
      return [{
        text: 'Code',
        align: 'start',
        sortable: false,
        value: 'code'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Is Active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  mounted () {
    this.$store.dispatch('getUserRoles').then((data) => {
      this.listOfUserRoles = data.filter((roleItem) => roleItem.code === 2)
    })
    this.$store.dispatch('getUsersList', { only: [ 2 ] }).then((data) => {
      this.listOfEmployees = data
    })
    this.getShiftItems()
    this.getDaysInMonth()
    this.getListHandler()
  },
  methods: {
    getShiftItems () {
      this.$_execute('get', 'shifts')
        .then(({ data }) => {
          this.listOfShifts = data
        })
    },
    checkCanUpdateBalanceDialog () {
      this.leaveTypeObj.noReCalculateUpdate = (this.leaveTypeObj.haslimit !== this.cloneLeaveTypeObj.haslimit)
      if ((this.leaveTypeObj.totallimit !== this.cloneLeaveTypeObj.totallimit) || (this.leaveTypeObj.noReCalculateUpdate)) {
        this.leaveTypeUpdateConfirmDialog = true
      } else this.saveUpdateHandler({ type: 'update' })
    },
    getDefaultLeaveProps () {
      this.getDaysInMonth()
      return {
        hasresetlimit: false,
        haslimit: false,
        resetmonth: 1,
        resetday: 1,
        limitFor: {},
        type: 'paid',
        isactive: true,
        // limitfor: 'year',
        resetat: 'year'
      }
    },
    getDaysInMonth (month = 1) {
      var daysInMonth = new Date(new Date().getFullYear(), month, 0).getDate()
      if (month === 1 && daysInMonth === 28) daysInMonth++
      var days = []
      for (let i = 1; i <= daysInMonth; i++) {
        days.push({ text: i, value: i })
      }
      // days.push({ text: this.$t('lastDay'), value: -1 })
      this.daysList = this.$_copy(days)
    },
    getListHandler () {
      this.$_execute('get', 'leave_type')
        .then(({ data }) => {
          this.leaveTypesPayload = { ...this.leaveTypesPayload, items: data }
        })
    },
    saveUpdateHandler ({ type } = {}) {
      if (this.$refs.form && this.$refs.form.validate()) {
        let url = (this.leaveTypeObj._id) ? `leave_type/${this.leaveTypeObj._id}` : `/leave_type`
        let method = (this.leaveTypeObj._id) ? `put` : `post`
        this.leaveTypeObj.adjustRemainingBalance = (type === 'recalculate')
        this.leaveTypeObj.resetBalance = (type === 'reset')
        this.$_execute(method, url, this.leaveTypeObj)
          .then(() => {
            this.$root.$emit('snackbar', { message: this.leaveTypeObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
            this.$refs.form.reset()
            this.leaveTypeObj = {}
            this.getListHandler()
            this.$eventBus.$emit('updateCustomBalanceTable')
          })
          .finally(() => {
            this.dialog = false
            this.leaveTypeUpdateConfirmDialog = false
          })
      } else {
        this.$root.$emit('snackbar', {
          color: 'error',
          message: 'Please check the required fields!'
        })
      }
    },
    openDialog () {
      this.leaveTypeObj = {
        hasresetlimit: false,
        haslimit: false,
        resetmonth: 1,
        resetday: 1,
        limitFor: {},
        type: 'paid',
        isactive: true,
        resetat: 'year'
      }
      this.dialog = true
    },
    editHandler (id) {
      this.$_execute('get', `leave_type/${id}`)
        .then(({ data }) => {
          this.leaveTypeObj = data
          this.cloneLeaveTypeObj = this.$_copy(data)
          this.dialog = true
        })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'leave_type',
        _id: id,
        onResolve: () => {
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
